import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import projectStyle from './projects.module.scss'
import Head from '../components/head'



const ProjectPage = () => {
  const data = useStaticQuery(graphql`
  {
    allContentfulProjectPost(sort: {fields: createdDate, order: DESC}) {
      edges {
        node {
          title
          slug
          createdDate(formatString: "MMMM Do, YYYY")
          image {
            file {
              url
            }
          }
          description {
            description
          }
        }
      }
    }
  }
    `);

  // AOS.init({
  //   offset: 200, // offset (in px) from the original trigger point
  //   delay: 0, // values from 0 to 3000, with step 50ms
  //   duration: 1200 // values from 0 to 3000, with step 50ms
  // });

  var l = true;
  var side = "";
  return (

    <div>
      <Layout>
        <Head title="Projects" />
        <h1>Projects</h1>
        <main className={projectStyle.container}>
          {data.allContentfulProjectPost.edges.map((edge) => {
            l = !l;
            if(l) {
              side = "slide-left"
            } else {
              side = "slide-right"
            }
            return (
              // <div className={projectStyle.post} data-aos="fade-left">
              //   <Link to={`/projects/${edge.node.slug}`}>
              //     <h2>
              //       {edge.node.title}
              //     </h2>
              //     <p>
              //       {edge.node.createdDate}
              //     </p>
              //   </Link>

              // </div>
              <section className={projectStyle.card} data-sal={side} data-sal-delay="0"
              data-sal-easing="ease" data-sal-duration="1200">
                <img src={edge.node.image.file.url} alt="" />
                <div>

                <h3>
                {edge.node.title}
                </h3>          
                <p className={projectStyle.date}>
                     {edge.node.createdDate}
                  </p>       
                 <p className={projectStyle.body}>
                    {edge.node.description.description}
                   </p>
                   <a href={`/projects/${edge.node.slug}`}>
                  <button className={projectStyle.btn}>
                    Read More
                   </button>
                  </a>
                   
                </div>

              </section>


                  )
          })}
        </main>
      </Layout>
    </div>
  )
}

export default ProjectPage